.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: #fcfcfc;
}

.logo-container, .navbar-buttons, .section-logo {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.logo-laIdea {
    cursor: pointer;
    width: 350px;
    height: 80px;
}

.navbar-buttons {
    gap: 10px;
}

.navbar-buttons .btn {
    color: #333;
    text-decoration: none;
    padding: 0;
}

.section-image {
    cursor: pointer;
    width: 250px;
    height: 80px;
}
