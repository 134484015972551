.contactanos-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: rgba(35, 47, 93); /* Fondo semi-transparente */
    backdrop-filter: blur(10px); /* Aplicar el efecto blur */
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Sombra para darle profundidad */
  }
  
  .contactanos-container h2 {
    text-align: center;
    color: #faf7f7;
  }
  
  .contactanos-container p {
    color: #fcfafa;
    line-height: 1.5;
    text-align: center;
  }
  
  form {
    margin-top: 20px;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-control {
    border-radius: 4px;
  }
  
  .form-check-label {
    margin-left: 10px;
  }
  .form-label {
    color: #faf7f7;
  }

  .form-check {
    color: #faf7f7;
  }
  
  button[type="submit"] {
    display: block;
    width: 100%;
    margin-top: 20px;
    background-color: rgba(195, 47, 48)
  }
  