.carousel-container {
    width: 100%;
    max-width: 1300px;
    margin: 0 auto;
}

.carousel {
    width: 100%;
    margin: auto;
}

.carousel-inner {
    width: 100%;
}

.carousel-item {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.carousel-item img {
    width: 100%;
    height: auto;
    max-height: 400px; /* Ajustar este valor según necesidad */
    object-fit: contain; /* Asegura que la imagen se ajuste correctamente dentro del contenedor */
}

/* Estilos personalizados para el caption del carrusel */
.carousel-caption {
    background-color: rgba(0, 0, 0, 0.5); /* Fondo semi-transparente */
    padding: 10px;
    backdrop-filter: blur(10px);
    border-radius: 10px;
    text-align: center; /* Centra el texto */
    width: 50%; /* Ajusta el ancho del caption */
    margin: auto;
    position: absolute;
    bottom: 10px; /* Ajusta la posición del caption */
    left: 0; 
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.carousel-caption h3 {
    color: rgb(180, 180, 182) !important;
    margin: 0;
    font-size: 1.2rem;
    font-weight: bold;
}

.carousel-caption p {
    color: white; /* Cambia el color del texto a blanco para mayor contraste */
    margin-top: 5px;
    font-size: 1rem;
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%; /* Asegura que el texto no se desborde */
}

/* Media Queries */
@media (max-width: 768px) {
    .carousel-caption {
        width: 50%; /* Ajusta el ancho del caption */
        bottom: 10px; /* Ajusta la posición del caption */
        padding: 8px;
    }

    .carousel-caption h3 {
        font-size: 1rem;
    }

    .carousel-caption p {
        font-size: 0.9rem;
    }

    .carousel-caption button {
        font-size: 0.9rem;
    }
}

@media (max-width: 576px) {
    .carousel-caption {
        width: 60%;
        bottom: 10px; /* Mantén el caption cerca del borde inferior */
        padding: 8px;
    }

    .carousel-caption h3 {
        font-size: 0.9rem;
    }

    .carousel-caption p {
        font-size: 0.8rem;
    }

    .carousel-caption button {
        font-size: 0.2rem;
    }
}