.radio-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #1db954; /* Color verde similar a Spotify */
  padding: 20px;
}

.upload-section, .audio-list-section {
  width: 100%;
  max-width: 600px; /* Ajusta el tamaño según sea necesario */
}

.audio-card {
  border: 1px solid #ccc; /* Borde de la tarjeta */
  border-radius: 8px; /* Bordes redondeados */
  background-color: #fff; /* Fondo blanco para las tarjetas */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Sombra ligera */
}

.audio-card .card-title {
  color: #333; /* Color del título */
  font-size: 1.25rem;
}

.audio-card .card-text {
  color: #666; /* Color del texto */
}

.audio-card small {
  color: #999; /* Color de la fecha */
}
