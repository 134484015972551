.diario-view {
    padding: 20px;
    transition: margin-left 0.3s;
    background-color: #f8f9fa;
}

.diario-view.dropdown-open .articles {
    margin-left: 200px; /* Ajusta este valor según sea necesario */
}

.banner {
    background-color: #4b4c4d;
    padding: 20px;
    border-radius: 8px;
    margin-bottom: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    z-index: 1000; /* Asegúrate de que el banner esté siempre encima */
    position: relative;
}

.banner-title {
    color: #ffffff; /* Texto blanco */
    text-align: left; /* Alinea el texto a la izquierda */
    margin: 0; /* Elimina el margen superior e inferior */
}

.banner .category-dropdown {
    margin-right: 10px;
}

.articles {
    display: flex;
    flex-direction: column;
    gap: 20px;
    transition: margin-left 0.3s;
}

.article {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border: 1px solid #0f0f0f; /* Añade un borde a cada artículo */
    margin-bottom: 20px; /* Añade un margen inferior para separar los artículos */
}

.article h3 {
    margin-top: 0;
}

.article p {
    margin-bottom: 10px;
}

.article .btn {
    align-self: flex-start;
}

.dropdown-item.active, .dropdown-item:active {
    background-color: #073e75;
    color: #ffffff;
}

@media (max-width: 768px) {
    .diario-view.dropdown-open .articles {
        margin-left: 0; /* Elimina el margen para pantallas pequeñas */
    }

    .banner {
        padding: 15px;
    }

    .articles {
        gap: 15px;
    }

    .article {
        padding: 15px;
    }
}