.edicion-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: rgba(35, 47, 93); /* Fondo semi-transparente */
    backdrop-filter: blur(10px); /* Aplicar el efecto blur */
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Sombra para darle profundidad */
  }
  
  .edicion-container h2 {
    text-align: center;
    color: #faf8f8;
  }
  
  .edicion-container p {
    color: #fcfbfb;
    line-height: 1.5;
    text-align: center;
  }
  
  form {
    margin-top: 20px;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-control {
    border-radius: 4px;
  }
  
  .form-check-label {
    margin-left: 10px;
  }
  
  button[type="submit"] {
    display: block;
    width: 100%;
    margin-top: 20px;
    background-color: rgba(195, 47, 48)
  }

  .image-gallery {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  .image-gallery img {
    width: 30%;
    cursor: pointer;
    transition: transform 0.3s ease;
  }
  
  .image-gallery img:hover {
    transform: scale(1.05);
  }
  
  .modal-image {
    width: 100%;
    height: auto;
    display: block;
    margin: 0 auto;
  }
  
  /* Media Queries */
  @media (max-width: 768px) {
    .edicion-container {
      padding: 10px;
    }
  
    .image-gallery {
      flex-direction: column;
      align-items: center;
    }
  
    .image-gallery img {
      width: 80%;
      margin-bottom: 10px;
    }
  
    .carousel-caption h3 {
      font-size: 1.2rem;
    }
  
    .carousel-caption p {
      font-size: 0.9rem;
    }
  
    .carousel-caption button {
      font-size: 0.9rem;
    }
  }
  
  @media (max-width: 576px) {
    .carousel-caption h3 {
      font-size: 1rem;
    }
  
    .carousel-caption p {
      font-size: 0.8rem;
    }
  
    .carousel-caption button {
      font-size: 0.8rem;
    }
  }