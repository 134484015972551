.nosotros-container {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    background-color: #f8f9fa; /* Color de fondo de la página */
    overflow-y: auto; /* Habilita el desplazamiento vertical */
  }
  
  .nosotros-content {
    background-color: rgba(35, 47, 93); /* Fondo semi-transparente */
    backdrop-filter: blur(10px); /* Aplicar el efecto blur */
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Sombra para darle profundidad */
    max-width: 600px;
    text-align: center;
    font-family: Arial, sans-serif; /* Fuente estándar */
  }
  
  .nosotros-content p {
    margin-bottom: 1.5em;
    color: #faf9f9; /* Color del texto */
    line-height: 1.5; /* Espaciado entre líneas */
  }
  