* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.footer {
    background: rgba(9, 22, 29, 0.712);
    height: auto;
    width: 100vw;
    font-family: "montserrat", sans-serif;
    padding-top: 10px;
    color: #fff;
    bottom: 0;
    
}


.footer-content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
}

.footer-content h3 {
    font-size: 1.2rem;
    font-weight: 400;
    text-transform: capitalize;
    line-height: 2rem;
}

.socials {
    list-style: none;
    padding: 0;
    display: flex;
    /* align-items: center; */
    justify-content: center;
    
    gap: 10px;
    /* margin: 1rem 0; */
    margin-top: 10px;
}

.socials li {
    /* margin: 0 10px; */
    display: inline;
}

.socials a {
    color: #333;
    font-size: 24px;
    text-decoration: none;
    transition: color 0.3s ease;
}

.socials a.facebook {
    color: #3b5998; /* Color oficial de Facebook */
}

.socials a.instagram {
    color: #e4405f; /* Color oficial de Instagram */
}

.socials a.facebook:hover,
.socials a.instagram:hover {
    color: #555;
}

.footer-bottom {
    background: rgba(9, 22, 29, 0.712);
    width: 100vw;
    padding: 20px 0;
    text-align: center;
}

.footer-bottom p {
    font-size: 12px;
    word-spacing: 2px;
    text-transform: capitalize;
}


.footer-bottom span {
    text-transform: uppercase;
    opacity: .4;
    font-weight: 200;
}