/* Home.css */
@import url('https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css');

.home-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.cards-container {
    margin-top: 20px;
}

.navigate-card {
    cursor: pointer;
    transition: transform 0.3s ease box-shadow 0.3s ease; /* Mantenemos la transición existente y añadimos una para el box-shadow */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Agregamos sombra para el efecto de elevación */
}

.navigate-card:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Aumentamos la sombra al hacer hover */
}

.navigate-image {
    max-height: 200px; /* Ajusta según tus necesidades */
    object-fit: cover;
}

.navigate-image2 {
    max-height: 200px;
    /* max-width: 100%; */
    object-fit: cover;
}

.card-text {
    margin-top: 10px;
    font-size: 16px;
    color: #292828;
    transition: color 0.3s ease; /* Transición de color */
}

.navigate-card:hover .card-text {
    color: #007bff; /* Cambia el color del texto al hacer hover */
}

.card-title {
    margin-top: 10px;
    font-size: 18px;
    font-weight: bold;
    color: #333;
    text-align: center;
    opacity: 0; /* Inicialmente oculto */
    transition: opacity 0.3s ease; /* Transición de opacidad */
}

.navigate-card:hover .card-title {
    opacity: 1; /* Se vuelve visible al hacer hover */
}

.card-body {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.logo-carousel {
    margin-top: 30px;
    padding: 20px;
    background-color: #f8f9fa;
    text-align: center;
    border: 1px solid red; /* Borde para ver los límites del carrusel */
    max-height: 240px; /* Ajusta el máximo alto del carrusel */
  }
  
  .logo-carousel img {
    max-height: 100px; /* Ajusta el tamaño máximo de las imágenes */
    width: auto;
    object-fit: contain; /* Mantén las proporciones de las imágenes */
    cursor: pointer;
    margin: 0 10px;
  }

@media (max-width: 768px) {
    .cards-container {
        flex-direction: column;
        align-items: center;
    }

    .navigate-card {
        width: 100%;
    }

    .logo-carousel img {
        width: 100px;
      }
}
