.admin-layout {
  min-height: 100vh;
}

.admin-header {
  background: #001529;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo {
  color: #fff;
  font-size: 20px;
  font-weight: bold;
  margin-left: 20px;
}

.admin-sider {
  background: #fff;
}

.admin-content {
  background: #fff;
  padding: 24px;
  margin: 0;
  min-height: 280px;
}

.loading-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
