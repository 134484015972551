.logo-carousel {
  width: 100%;
  max-height: 150px; /* Adjust the height for a smaller carousel */
  overflow: hidden;
  margin-top: 20px;
  border: 1px solid black;
  }

  .ant-carousel .slick-slide {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .logo-carousel img {
    max-height: 100px; /* Ensure the images fit within the carousel */
    max-width: 100%;
    margin: 0 auto;
    display: block;  
  }

  .carousel-group {
    display: flex;
    justify-content: space-between; /* Espaciado entre las imágenes */
    width: 100%;
  }
  
  .carousel-slide {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  /* .carousel-wrapper {
    position: relative;
    margin-top: 20px;
    border: 1px solid blue;
  } */