.concurso-container {
  padding: 20px;
}

.header-image {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh; /* Ajusta la altura según sea necesario */
  margin-bottom: 20px;
}

.large-image {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
}

.dropdown-toggle {
  margin-top: 20px;
}

.tab-content {
  padding: 20px;
  margin-top: 20px;
}

.ant-tabs-card .ant-tabs-tab {
  background-color: #f5f5f5;
}

.ant-tabs-card .ant-tabs-tab-active {
  background-color: #fff;
}

.section-title {
  text-align: center;
  margin-bottom: 20px;
}

.concurso-content p {
  text-align: justify;
  font-size: 1rem;
}

.subcategory-options {
  margin-top: 20px;
}

.subcategory-options ul {
  list-style-type: none;
  padding: 0;
}

.subcategory-options ul li {
  cursor: pointer;
  padding: 10px;
  background-color: #f8f8f8;
  margin-bottom: 5px;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.subcategory-options ul li:hover {
  background-color: #d9d9d9;
}

